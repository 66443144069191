import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideAnimations } from '@angular/platform-browser/animations';

import {bootstrapApplication} from '@angular/platform-browser';
import { DswModule } from './dsw.module';
import {SpeakerInterestsFormComponent} from './speaker-interests-form/speaker-interests-form.component';
import { UserQrVcardComponent } from './user-qr-vcard/user-qr-vcard.component';
import { VenueSignageComponent } from './venue-signage/venue-signage.component';
import { VenueLocationComponent } from './venue-location/venue-location.component';
import { TargetAudiemceChipsComponent } from './target-audiemce-chips/target-audiemce-chips.component';


platformBrowserDynamic().bootstrapModule(DswModule)
  .catch(err => console.error(err));
  
console.log("Angular loaded");
export function  bootSpeakerInterestsForm() {
  bootstrapApplication(SpeakerInterestsFormComponent, {
    providers: [
      provideAnimations()
    ]
  });
}
window['bootSpeakerInterestsForm'] = () => bootSpeakerInterestsForm();

export function  bootUserQrVcard() {
  bootstrapApplication(UserQrVcardComponent, {
    providers: [
      provideAnimations()
    ]
  });
}
window['bootUserQrVcard'] = () => bootUserQrVcard();

export function  bootVenuSignage() {
  bootstrapApplication(VenueSignageComponent, {
    providers: [
      provideAnimations()
    ]
  });
}
window['bootVenuSignage'] = () => bootVenuSignage();

export function  bootVenuLocation() {
  bootstrapApplication(VenueLocationComponent, {
    providers: [
      provideAnimations()
    ]
  });
}
window['bootVenuLocation'] = () => bootVenuLocation();

export function  bootTargetAudiemceChips() {
  bootstrapApplication(TargetAudiemceChipsComponent, {
    providers: [
      provideAnimations()
    ]
  });
}
window['bootTargetAudiemceChips'] = () => bootTargetAudiemceChips();
