import { Component, Input, HostBinding } from '@angular/core';

import { VenueSession } from '../dsw.service';

@Component({
  selector: 'session-card',
  templateUrl: './session-card.component.html',
  styleUrls: ['./session-card.component.scss']
})
export class SessionCardComponent {
  @Input() venueSession: VenueSession;
  @Input() isActive: boolean;
  @HostBinding('class') get cssClass() {
    if (this.venueSession && this.venueSession.track) {
      return this.venueSession.track.name.toLowerCase() + '-session';
    }
    return 'growth-session';
  }
  
  constructor() { }
  
  get sessionDate(): string {
    if (this.venueSession) {
      return this.venueSession.start_time.format("dddd, MMM DD");
    }
    return "";
  }
  
  get sessionTime(): string {
    if (this.venueSession) {
      return this.venueSession.start_time.format("hh:mm A") + " - " + this.venueSession.end_time.format("hh:mm A");
    }
    return "";
  }

}
