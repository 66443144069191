import { Component, Input, ElementRef } from '@angular/core';

import { DswSoModule } from '../dsw-so.module';

export interface TargetAudience {
  whoFor: string[];
  tags: string[];
}

@Component({
  standalone: true,
  imports: [DswSoModule],
  selector: 'target-audiemce-chips',
  templateUrl: './target-audiemce-chips.component.html',
  styleUrls: ['./target-audiemce-chips.component.scss']
})
export class TargetAudiemceChipsComponent {
  _targetAudience: TargetAudience;
  @Input() set targetAudience(targetAudience: TargetAudience) {
    this._targetAudience = targetAudience;
    console.log("Got Audiance ", targetAudience);
  }
  get targetAudience(): TargetAudience {
    return this._targetAudience
  }
  @Input() expertizeLevel: string;
  
  constructor(public element: ElementRef) {
    console.log("In constructor", element.nativeElement.getAttribute("targetaudience"));
    let targetString = element.nativeElement.getAttribute("targetaudience");
    if (targetString) {
      this.targetAudience = JSON.parse(targetString);
    }
    this.expertizeLevel = element.nativeElement.getAttribute("expertizelevel");
  }

}
