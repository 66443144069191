import { Component, Input } from '@angular/core';
import * as moment from 'moment';

import { DswService, VenueSession, Venue } from '../dsw.service';

@Component({
  selector: 'sessions-here',
  templateUrl: './sessions-here.component.html',
  styleUrls: ['./sessions-here.component.scss']
})
export class SessionsHereComponent {
  activeSession: VenueSession;
  commingSession: VenueSession;
  thatsAll = false;
  venue: Venue;
  sessions: VenueSession[];
  _venueId: string
  @Input() set venueId(venueId: string) {
    if (this._venueId != venueId) {
      this._venueId = venueId;
      this.dswService.getVenuSessions(this.venueId).subscribe((results) => {
        this.venue = results;
        this.sessions = [];
        console.log("sessions", results);
        for (let session of results.sessions) {
            let start_time = moment(session.start_time);
            let end_time = moment(session.start_time).add(session.duration, 'minutes');
            this.sessions.push(Object.assign({}, session, {start_time: start_time, end_time: end_time})); 
        }
      });
    } 
  };
  get venueId(): string {
    return this._venueId;
  }
  
  constructor(public dswService: DswService) { 
    this.dswService.nowChangeEmitter.subscribe((now) => this.nowChanged(now));
  }

  nowChanged(now: moment.Moment) {
    let activeSessionFound = false;
    let commingSessionFound = false;

    if (now && ((now.get('date') == 23 && now.hour() > 16) || now.get('date') > 23)) {
      this.activeSession = undefined;
      this.commingSession = undefined;
      this.thatsAll = true;
      return;
    }
    if (this.sessions && now) {
      for (let checkSession of this.sessions) {
        if ((checkSession.start_time.isBefore(now) || checkSession.start_time.isSame(now)) && checkSession.end_time.isAfter(now)) {
          this.activeSession = checkSession;
          activeSessionFound = true;
        }
        if (checkSession.start_time.isAfter(now) && !commingSessionFound) {
          this.commingSession = checkSession;
          commingSessionFound = true;
        }
      }
    }
    if (!activeSessionFound) {
      this.activeSession = undefined;
    }
    if (!commingSessionFound) {
      this.commingSession = undefined;
    }
  }


}
