import 'zone.js';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { QRCodeModule } from 'angularx-qrcode';
import { IconsModule } from './icons/icons.module';

import { DswService } from './dsw.service';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { CreateCompanyDialogComponent } from './create-company-dialog/create-company-dialog.component';
import { EditVcardComponent } from './edit-vcard/edit-vcard.component';
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import { SessionCardComponent } from './session-card/session-card.component';
import { PickVenueComponent } from './pick-venue/pick-venue.component';
import { SessionsHereComponent } from './sessions-here/sessions-here.component';
import { SponsorshipsSliderComponent } from './sponsorships-slider/sponsorships-slider.component';

import { SponsorshipsSlideComponent } from './sponsorships-slider/sponsorships-slide.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    QRCodeModule,
    IconsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    QRCodeModule,
    IconsModule,
    UserAutocompleteComponent,
    SessionCardComponent,
    PickVenueComponent,
    SessionsHereComponent,
    SponsorshipsSliderComponent,
    SponsorshipsSlideComponent
  ],
  providers: [
    DswService,
    Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  declarations: [
    UserAutocompleteComponent,
    CreateCompanyDialogComponent,
    EditVcardComponent,
    ImagePlaceholderComponent,
    SessionCardComponent,
    PickVenueComponent,
    SessionsHereComponent,
    SponsorshipsSliderComponent,
    SponsorshipsSlideComponent
  ]
})
export class DswSoModule { 
}
