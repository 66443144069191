<mat-card>
  <mat-card-header>
    <mat-card-subtitle class="track">
      <track-icon [track]="venueSession.track"></track-icon>
      <div class="track-name">{{venueSession.track.name}}&nbsp;Track Event</div>
    </mat-card-subtitle>
    <mat-card-subtitle>
      <span>{{sessionDate}}</span>
      &nbsp;&nbsp;
      <span>{{sessionTime}}</span>
    </mat-card-subtitle>
	  <mat-card-title>{{venueSession.title}}</mat-card-title>
	  <mat-card-subtitle>{{venueSession.company_or_submitter}}</mat-card-subtitle>
  </mat-card-header>
</mat-card>  
  
