import 'zone.js';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopComponent } from './noop/noop.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
  ],
  exports: [
  ],
  declarations: [
    NoopComponent
  ],
  bootstrap: [NoopComponent]
})
export class DswModule { 
}
