import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { DswService } from '../dsw.service';

export interface Venue {
  name: string;
  venue_id: string;
}
@Component({
  selector: 'pick-venue',
  templateUrl: './pick-venue.component.html',
  styleUrls: ['./pick-venue.component.scss']
})
export class PickVenueComponent {
  venues: Venue[] = [
    {
      venue_id: "484",
      name: "Growth Track - Expansive 1630 Welton St"
    }, {
      venue_id: "483",
      name: "People Track - Jake Jabs Center  1475 Lawrence St"
    }, {
      venue_id: "481",
      name: "Founder Track - Polsinelli 1401 Lawrence St UNIT 2300"
    }, {
      venue_id: "415",
      name: "DSW HQ POWERED BY AMAZON 1611 Arapahoe St."
    }, {
      venue_id: "223",
      name: "Product Track - CBRE 1225 17th St  Suite 3200"
    }, {
      venue_id: "485",
      name: "Designer Track Venue - Greenspoon Marder 1144 15th St  Suite 2700"
    }, {
      venue_id: "429",
      name: "Maker Track Venue - Denver Place Conference Room 999 18th St - Conference Room"
    }, {
      venue_id: "423",
      name: "Developer Track - Park Central 1515 Arapahoe St. - Floor 2 Conference Room"
    }, {
      venue_id: "145",
      name: "Spotlight Track - Commons on Champa  1245 Champa St."
    }];
  selectedVenues: string[] = [];
  get noVenueSelected(): boolean {
    return this.selectedVenues.length == 0;
  }

  displayVenues() {
    let venueParam = "";
    let first = true;
    for (let venue_id of this.selectedVenues) {
      if (!first) {
        venueParam += "&";
      }
      first = false;
      venueParam += "venue=" + venue_id;
    }
    if (window.location.search && window.location.search.includes("test=true")) {
      window.location.search = window.location.search + "&" + venueParam;
    }
    else {
      window.location.search = venueParam;
    }
  }
  constructor(public dswService: DswService) {
  }


  selectVenue(selectedEvent: MatSelectChange) {
    console.log("Venue selected ", selectedEvent);
    this.selectedVenues = selectedEvent.value;


    // this.venueComponent._venueId = selectedEvent.value;    
  }

}
