import { Component, Input, ViewChildren, QueryList } from '@angular/core';

import { DswService, Sponsorship, Sponsor } from '../dsw.service';
import { SponsorshipsSlideComponent } from './sponsorships-slide.component';

export interface SponsorSizing {
  widthFraction: number;
  paddingFraction: number;
  count: number;
}

export interface SponsorSizings {
  [key: string]: SponsorSizing
}

@Component({
  selector: 'sponsorships-slider',
  templateUrl: './sponsorships-slider.component.html',
  styleUrls: ['./sponsorships-slider.component.scss']
})
export class SponsorshipsSliderComponent {
  @ViewChildren('sposorshipSlide') sponsorshipSlides: QueryList<SponsorshipsSlideComponent>;
  sponsorSizings: SponsorSizings = {
    presenting: {
      widthFraction: .25,
      paddingFraction: 0,
      count: 1
    },
    title: {
      widthFraction: .20,
      paddingFraction: .05,
      count: 3
    },
    track: {
      widthFraction: .16,
      paddingFraction: 0,
      count: 4
    },
    headline: {
      widthFraction: .16,
      paddingFraction: .05,
      count: 4
    },
    pitch: {
      widthFraction: .12,
      paddingFraction: .05,
      count: 6
    },
    partner: {
      widthFraction: .12,
      paddingFraction: .05,
      count: 6
    },
    member: {
      widthFraction: .12,
      paddingFraction: .05,
      count: 6
    },
    media: {
      widthFraction: .12,
      paddingFraction: .05,
      count: 6
    },
    'social event': {
      widthFraction: .12,
      paddingFraction: .05,
      count: 6
    }

  };
  newSponsorsInterval: any;
  sponsorsPages: Sponsorship[];
  sponsorSlideIndex = 0;
  sponsorDivStyle(sponsorship: Sponsorship) {
    let sponsorSizing: SponsorSizing = this.sponsorSizings[sponsorship.level];
    let width = this.dswService.containerWidthpx * sponsorSizing.widthFraction;
    let maxHeight = width * .32;
    let style = {
      'width.px': width,
      'max-height.px': maxHeight,
      'padding.px': sponsorSizing.paddingFraction * width
    }
    return style;
  }

  // _sponsorships: Sponsorship[];
  @Input() set sponsorships(sponsorships: Sponsorship[]) {
    this.sponsorsPages = [];
    let showSponsors: Sponsor[];
    for (let showSponsorship of sponsorships) {
      let sponsorSizing: SponsorSizing = this.sponsorSizings[showSponsorship.level];
      let sponsorIndex = 0;
      while (sponsorIndex < showSponsorship.sponsors.length) {
        let startIndex = sponsorIndex
        showSponsors = [];
        for (let index = startIndex; index < showSponsorship.sponsors.length && index < startIndex + sponsorSizing.count; index++) {
          showSponsors.push(showSponsorship.sponsors[index]);
        }
        this.sponsorsPages.push({ level: showSponsorship.level, sponsors: showSponsors, sponsorDivStyle: this.sponsorDivStyle(showSponsorship) });
        this.sponsorsPages.push({ level: "QR Codes" });
        sponsorIndex += sponsorSizing.count;
      }

    }
      setTimeout(() => {
        this.newSponsorsInterval = setInterval(() => this.newSponsors(), this.dswService.sponsorDelay);
      }, 500);
  }
  newSponsors() {
    if (this.sponsorshipSlides) {
      if (this.sponsorSlideIndex >= 0) {
        let sponsoshipSlide = this.sponsorshipSlides.get(this.sponsorSlideIndex);
        sponsoshipSlide.display = false;
      }
      this.sponsorSlideIndex++;
      if (this.sponsorSlideIndex >= this.sponsorshipSlides.length) {
        this.sponsorSlideIndex = 0;
      }
      let sponsoshipSlide = this.sponsorshipSlides.get(this.sponsorSlideIndex);
      sponsoshipSlide.display = true;
    }
  }
  constructor(public dswService: DswService) {

  }


}
