import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse, HttpParams, HttpClient } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface Track {
  id: number;
  name: string;
}

export interface Company {
  id: number;
  name: string;
}

export interface User {
  id: number;
  name: string;
  email: string
  linkedin_url: string;
}

export interface SpeakerInterest {
    title: string;
    bio: string;
    alt_email: string;
    mobile_phone_number: string;
    potential_topic: string;
    notes: string;
    user: User;
    company: Company;
    track: Track;
    user_id: number;
    company_id: number;
    track_id: number;
    format: string;
    expertise_level: string;
}

export interface SelectValues {
  expertises: string[];
  formats: string[];
  tracks: Track[];
}

export interface Track {
  name: string;
  icon: string;
  color: string;
} 

export interface VenueSession {
    title: string;
    description: string;
    company_or_submitter: string,
    start_time: moment.Moment;
    end_time?: moment.Moment;
    duration: number,
    subtitle: string,
    track: Track,
    presenters: []
}

export interface Venue {
  name: string;
  address: string; 
  sessions: VenueSession[];
}

export interface VenueLocation {
	name: string;
	address: string;
	suite_or_unit: string;
	city: string;
	state: string;
	lat: string;
	long: string;
	lookupId: string;
	apiKey: string;
}

export interface Sponsor {
  description: string;
  logo: string;
  level: string;
  track?: Track;
}

export interface Sponsorship {
  level: string;
  sponsors?: Sponsor[];
  sponsorDivStyle?: any;
}

@Injectable()
export class DswService {
	sponsorDelay = 6000;
  defaultWidthpx = 1920;
  defaultHeightpx = 1080;
  containerWidthpx = 1920;
  containerHeightPx = 1080;
  nowChangeEmitter: Subject<moment.Moment> = new Subject<moment.Moment>();

  constructor(protected http: HttpClient) {
  }

  getSpeakerInterest(id: string, csrf: string): Observable<SpeakerInterest> {
    return this.http.post<SpeakerInterest>('/speaker-interests/ngedit/' + id, {}, {headers: {"X-CSRF-Token": csrf}}).pipe(
      catchError(error => this.handleError(error)));
  }

  getSubmissionSelectValues(csrf: string): Observable<SelectValues> {
    return this.http.post<SelectValues>('/submissions/select-values', {}, {headers: {"X-CSRF-Token": csrf}}).pipe(
      catchError(error => this.handleError(error)));
  }

  getSponsors(): Observable<Sponsorship[]> {
    return this.http.post<Sponsorship[]>('/signage_sponsors', {}).pipe(
      catchError(error => this.handleError(error)));
  }

  getVenuLocation(venue: string): Observable<VenueLocation> {
    return this.http.get<Venue>('/venue-location/' + venue, {}).pipe(
      catchError(error => this.handleError(error)));
  }

  getVenuSessions(venue: string): Observable<Venue> {
    return this.http.post<Venue>('/venue-signage/' + venue, {}).pipe(
      catchError(error => this.handleError(error)));
  }

  postFormData(url: string, formData:FormData, csrf: string) {
    return this.http.post<any>(url, formData, {headers: {"X-CSRF-Token": csrf, 'Accept': 'application/json'}, observe: 'response'}).pipe(
      catchError(error => this.handleError(error)));;

  }
  
  getCompanies(query: string, csrf: string) {
    // http://localhost:3000/admin/companies/filter?q[name_cont]=Pi&limit=5&order=name ASC&searchFields[]=name
    let params = new HttpParams();
    params = params.append("q[name_cont]", query)
    .append("limit",50)
    .append("order","name ASC")
    .append("searchFields[]","name")
    return this.http.get<Company[]>('/admin/companies/filter', {params: params}).pipe(
      catchError(error => this.handleError(error)));
  }

  getUsers(query: string, csrf: string) {
    let params = new HttpParams();
    params = params.append("q[name_or_email_cont]", query)
    .append("limit",50)
    .append("order","name ASC")
    .append("searchFields[]","name")
    .append("searchFields[]","email");
    // return this.http.get<User[]>('/admin/users/filter', {headers: {"X-CSRF-Token": csrf}, params: params}).pipe(
    return this.http.get<User[]>('/admin/users/filter', {params: params}).pipe(
      catchError(error => this.handleError(error)));
  
    
    // ?q[name_or_email_cont]=th&limit=5&order=name ASC&searchFields[]=name&searchFields[]=email
    
  }

  postVCard(url: string, card: string, csrf: string): Observable<any> {
    return this.http.post(url, card, {headers: {"X-CSRF-Token": csrf, 'Content-Type': 'text/vcard'}}).pipe(
      catchError(error => this.handleError(error)));
  }
  
  getVCard(url: string) {
    return this.http.get(url, {responseType: "text"}).pipe(
      catchError(error => this.handleError(error)));
  }
  
  public handleError(error: HttpResponse<any>): Observable<any> {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      let errorResponse: HttpErrorResponse = error;
      if (errorResponse.error && errorResponse.error.messageType) {
        errMsg = `${errorResponse.error.messageType} - ${errorResponse.error.message || ''}`;
      }
      else {
        errMsg = `${errorResponse.name} - ${errorResponse.message || ''}`;
      }

    } else {
      errMsg = error.body && error.body.message ? error.body.message : error.toString();
    }

    console.error(errMsg);
    let newError = Object.assign({}, error, { errorEmmitted: true })
    throw (newError);
  }

  jsonToForm(arrayValue: string, values:object): FormData {
    let formData: FormData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(arrayValue + "[" + key + "]", values[key]);
    }); 
    return formData;
  }
/*
speaker_interest[user_id]: 17847
speaker_interest[company_id]: 29597
speaker_interest[track_id]: 3
speaker_interest[title]: Test SI
speaker_interest[bio]: Old Dude
speaker_interest[alt_email]: 
speaker_interest[mobile_phone_number]: 
speaker_interest[potential_topic]: War stories
speaker_interest[format]: Individual Presentation
speaker_interest[expertise_level]: Advanced
speaker_interest[notes]: 
commit: Update Speaker interest
*/
}